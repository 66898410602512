






































































































import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/module'
import { scrollToElem } from '@/utils/scroll'

import FooterBlock from '@/components/FooterBlock.vue'
import ContactBlock from '@/components/ContactBlock.vue'

import KitCard from './components/KitCard.vue'
import TableBlock from './components/TableBlock.vue'
import Flow, { FlowRow } from './components/Flow.vue'

import Swiper, { Pagination }  from 'swiper'
import 'swiper/swiper-bundle.css'

import Kit1 from './data/kit1'
import Kit2 from './data/kit2'
import Kit3 from './data/kit3'

Swiper.use([Pagination])

@Component({
  components: {
    FooterBlock,
    ContactBlock,
    KitCard,
    TableBlock,
    Flow,
  },
})
export default class SystemKit extends Vue {
  /** 当前展示的解决方案索引 h5 */
  solutionIndex = 0

  /** 解决方案 */
  solutions = [{
    title: `
      網上外賣
      解決方案
    `,
    desc: '適用於僅需外賣自取/外賣送餐，支持訂單操作、及簡易的數據統計功能',
  }, {
    title: `
      外賣型餐廳
      解決方案
    `,
    desc: '適用於外賣型單店餐廳，無堂食，但需要電子餐牌，服務流程電子化，基礎數據報表等功能。需要配合平板電腦+打印機',
  }, {
    title: `
      標準餐廳
      解決方案
    `,
    desc: '適用於一般單店餐廳，需要堂食/外賣。需搭配平板電腦+打印機。建議同時使用CRM功能及移動落單POS功能',
  }, {
    title: '定制服務',
    tag: `
      大型/連鎖餐廳、
      FoodCourt、放題等
    `,
    desc: '為了更貼合您的業務流程和好的服務你，PinMe針對特殊業務類型的商戶，提供定制服務。如需定制，請聯繫PinMe銷售顧問'
  }]

  /** 当前展示的套件索引 h5 */
  kitIndex = 0

  /** 套件 */
  kits: FlowRow[][] = [Kit1, Kit2, Kit3]

  get isH5() {
    return AppModule.isH5
  }

  /** 套件介绍 */
  get kitInfo() {
    return this.kits.map(item => ({
      title: item[0].value,
      desc: item[0].extraValue,
    }))
  }

  /** 单行套件 h5 */
  get kit(): FlowRow[] {
    return this.kits[this.kitIndex].slice(1)
  }

  /** 套件表格 pc */
  get kitTable(): FlowRow[][] {
    const maxLen = Math.max(...this.kits.map(item => item.length))
    // 补齐长度
    return this.kits.map(item => {
      if (item.length < maxLen) {
        return item.concat(Array.from(new Array(maxLen - item.length), () => ({
          type: 'text',
          label: '',
          value: '',
        })))
      }
      return item
    })
  }

  mounted() {
    if (this.isH5) {
      // 解决方案 Swiper
      const solutionSwiper = new Swiper('.solution-swiper-container', {
        spaceBetween: 15,
        slidesPerView: 1.4,
        centeredSlides: true,
        pagination: {
          el: '.solution-swiper-pagination',
          clickable: true
        },
      })
      solutionSwiper.on('slideChange', this.changeSolutionIndex)

      // 套件 Swiper
      const kitSiper = new Swiper('.kit-swiper-container', {
        spaceBetween: 15,
        slidesPerView: 1.4,
        centeredSlides: true,
        pagination: {
          el: '.kit-swiper-pagination',
          clickable: true
        },
      })
      kitSiper.on('slideChange', this.changeKitIndex)
    }
  }

  scrollToContact() {
    scrollToElem('#contact', 500, 80)
  }

  changeSolutionIndex(swiper: Swiper) {
    this.solutionIndex = swiper.activeIndex
  }

  changeKitIndex(swiper: Swiper) {
    this.kitIndex = swiper.activeIndex
  }
}
