








import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class KitCard extends Vue {
  @Prop(String)
  title: string

  @Prop(String)
  desc: string

  @Prop(String)
  tag: string
}
