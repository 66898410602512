


















import { Component, Prop, Vue } from 'vue-property-decorator'
import { TableRow } from './TableBlock.vue'

export interface FlowRow extends TableRow {
  /**
   * 条目类型
   * caption: 大标题
   * title: 小标题
   * text: 文本
   */
  type: string;
  /** 文本 */
  label?: string;
  /** 值 */
  value?: boolean | string;
  /** 额外的值 */
  extraValue?: string;
}

@Component
export default class Flow extends Vue {
  @Prop(Array)
  data: FlowRow[]
}
