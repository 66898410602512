export default [
  {
    type: 'caption',
    value: '團單kit',
    extraValue: '團是為了針對優化配送效率，選擇集中收單，集中配送這種業務形式。團指的是在一個時間段內的訂單，如周一至週三下單的訂單，可在周四進行配送',
  },
  {
    type: 'title',
    label: '團設置',
  },
  {
    type: 'text',
    label: '自定義團週期',
  },
  {
    type: 'text',
    label: '自定義團的截單日期/時間',
  },
  {
    type: 'text',
    label: '支持多個團並存',
  },
  {
    type: 'text',
    label: '支持填寫配送聲明',
  },
  {
    type: 'title',
    label: '訂單',
  },
  {
    type: 'text',
    label: '團支持自提&配送的訂單',
  },
  {
    type: 'text',
    label: '支持以團維度的訂單整理',
  },
  {
    type: 'title',
    label: '數據',
  },
  {
    type: 'text',
    label: '支持導出團的訂單數據報表',
  },
  {
    type: 'text',
    label: '支持導出訂單的顧客信息',
  },
  {
    type: 'text',
    label: '支持導出菜品的備貨單',
  },
]