



























import { Component, Vue, Prop } from 'vue-property-decorator'
import { AppModule } from '@/store/module'
import Flow, { FlowRow } from './Flow.vue'

// 解决方案
import Solutions from '../data/solution'
// 定制服务
import CustomData from '../data/custom'

export interface TableRow {
  /**
   * 条目类型
   * caption: 大标题
   * title: 小标题
   * text: 文本
   */
  type: string;
  /** 文本 */
  label?: string;
  /** 值 */
  value?: boolean | string | (string | boolean)[];
  subValue?: string;
}

@Component({
  components: {
    Flow,
  },
})
export default class TableBlock extends Vue {
  /** 是否隐藏更多 */
  isFold = true

  $el!: HTMLDivElement

  /** h5 情况下只显示一条 */
  @Prop(Number)
  index: number

  /** 解决方案表格 */
  solutionTable: TableRow[] = Solutions

  /** 定制服务 */
  customData: FlowRow[] = CustomData

  get isH5() {
    return AppModule.isH5
  }

  get flowData(): FlowRow[] {
    if (!this.isH5 || this.index === 3) {
      return this.customData
    }
    return this.solutionTable.map(item => ({
      ...item,
      value: Array.isArray(item.value) ? item.value[this.index] : item.value
    }))
  }

  mounted() {
    if (this.$el.offsetHeight > 1000) {
      this.isFold = true
    }
  }
}
