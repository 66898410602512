
























































import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/module'
import ContactWay from './ContactWay.vue'

@Component({
  components: {
    ContactWay,
  }
})
export default class ContactBlock extends Vue {
  email = ''
  tel = ''
  name = ''

  loading = false

  get isH5() {
    return AppModule.isH5
  }

  submitForm() {
    if (!this.tel && !this.email) {
      alert('請填寫您的聯繫電話或郵箱地址')
      ;(this.$refs.email as HTMLInputElement).focus()
      return false
    }
    if (!this.name) {
      alert('請填寫您的稱呼')
      ;(this.$refs.name as HTMLInputElement).focus()
      return false
    }

    const xhr = new XMLHttpRequest()
    xhr.open('get', `https://meal.pin2eat.com/home/send-consult-email?email=${this.email}&tel=${this.tel}&name=${this.name}`)
    xhr.send()
    this.loading = true
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          try {
            const res = JSON.parse(xhr.response)
            if (res && res.code === 200) {
              this.email = this.tel = this.name = ''
              alert('提交成功')
            }
          } catch {
            alert('提交失敗，請刷新後重試')
          }
        }
      }
      this.loading = false
    }
  }
}
