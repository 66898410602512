export default [
  {
    type: 'caption',
    value: '會員營銷kit',
    extraValue: '適用於希望通過會員系統來留住老用戶、吸引老用戶持續消費的餐廳，並願意為老客戶提供差異化的權益和服務，不斷發掘老用戶的價值。 ',
  },
  {
    type: 'title',
    label: '會員運營平台',
  },
  {
    type: 'text',
    label: '搭建會員體系',
  },
  {
    type: 'text',
    label: '會員信息管理',
  },
  {
    type: 'text',
    label: '會員優惠設置',
  },
  {
    type: 'title',
    label: '會員運營工具',
  },
  {
    type: 'text',
    label: '支持訂單的立減/折扣優惠券',
  },
  {
    type: 'text',
    label: '支持積分/印花功能',
  },
  {
    type: 'text',
    label: '支持會員短信推送（不含SMS成本）',
  },
  {
    type: 'text',
    label: '支持線上支付完成，自動註冊會員',
  },
  {
    type: 'text',
    label: '支持線上點餐引導 關注 公眾號',
  },
  {
    type: 'text',
    label: '支持多碼合一',
  },
]