





















import { Component, Vue, Prop } from 'vue-property-decorator'
import { AppModule } from '@/store/module'

@Component
export default class ContactWay extends Vue {
  // Facebook 二维码
  imgSrc = 'https://spics.wantu.cn/45521a946b10e647fcfbb750984e7f48.png'

  @Prop({
    type: String,
    validator: val => ['Facebook', 'WhatsApp', 'Telegram'].indexOf(val) > -1,
    required: true,
  })
  type: string

  @Prop({
    type: String,
    validator: val => ['left', 'center', 'right'].indexOf(val) > -1,
    default: 'left'
  })
  dir: string

  get isH5() {
    return AppModule.isH5
  }

  clickHandle() {
    if (this.type === 'Facebook') {
      window.open('https://www.facebook.com/PIN2EAT/')
    }
  }
}
