/** 解決方案 */
export default [
  {
    type: 'caption',
    label: '',
    value: [
      `
      網上外賣
      解決方案
      `,
      `
      外賣型餐廳
      解決方案
      `,
      `
      標準餐廳
      解決方案
      `,
    ]
  },
  {
    type: 'title',
    label: '支付',
    value: '',
  },
  {
    type: 'text',
    label: '商户線下收款',
    value: [false, true, true],
  },
  {
    type: 'text',
    label: '對接不同線下支付方式',
    value: [false, true, true],
  },
  {
    type: 'text',
    label: '對接不同線上支付方式',
    value: [true, true, true],
  },
  {
    type: 'title',
    label: '手機點餐',
    value: '',
  },
  {
    type: 'text',
    label: '支持堂食',
    value: [false, true, true],
  },
  {
    type: 'text',
    label: '支持外賣自取',
    value: [true, true, true],
  },
  {
    type: 'text',
    label: '支持外賣自取點',
    value: [true, true, true],
  },
  {
    type: 'text',
    label: '支持外賣送餐',
    value: [true, true, true],
  },
  {
    type: 'text',
    label: '電子餐牌',
    value: [true, true, true],
  },
  {
    type: 'text',
    label: '手機落單',
    value: [true, true, true],
  },
  {
    type: 'text',
    label: 'Wechat Pay & Alipay HK 小程式手機落單',
    value: [true, true, true],
  },
  {
    type: 'text',
    label: '雲端訂單管理',
    value: [true, true, true],
  },
  {
    type: 'text',
    label: '優惠活動管理',
    value: [true, true, true],
  },
  {
    type: 'text',
    label: '優惠活動海報模版',
    value: [false, false, true],
  },
  {
    type: 'text',
    label: '跨平台（微信、FB、Alipay HK、主流手機瀏覽器）',
    value: [true, true, true],
  },
  {
    type: 'text',
    label: '實時平台通知（SMS/微信消息）',
    value: [true, true, true],
  },
  {
    type: 'title',
    label: '商户端',
    value: '',
  },
  {
    type: 'text',
    label: '業務模式-堂食',
    value: [false, true, true],
  },
  {
    type: 'text',
    label: '業務模式-外賣自取',
    value: [true, true, true],
  },
  {
    type: 'text',
    label: '業務模式-外賣自取點',
    value: [true, true, true],
  },
  {
    type: 'text',
    label: '業務模式-外賣送餐',
    value: [true, true, true],
  },
  {
    type: 'text',
    label: '餐牌管理（單品+套餐）',
    value: [true, true, true],
  },
  {
    type: 'text',
    label: '菜式分組管理',
    value: [true, true, true],
  },
  {
    type: 'text',
    label: '店員落單',
    value: [false, true, true],
  },
  {
    type: 'text',
    label: '訂單管理',
    value: [true, true, true],
  },
  {
    type: 'text',
    label: '桌枱管理',
    value: [false, false, false],
  },
  {
    type: 'text',
    label: '後廚管理（後廚票據&打印機連接）',
    value: [false, true, true],
  },
  {
    type: 'text',
    label: '收銀管理',
    value: [false, true, true],
  },
  {
    type: 'text',
    label: '促銷活動管理',
    value: [false, false, false],
  },
  {
    type: 'text',
    label: '数據報表（基礎財務+營運數據）',
    value: [false, true, true],
  },
  {
    type: 'text',
    label: '多硬件同時使用支持（可選）',
    value: [false, false, true],
  },
  {
    type: 'text',
    label: '多賬號管理',
    value: [false, false, false],
  },
  {
    type: 'text',
    label: '*多門店管理',
    value: [false, false, false],
  },
  {
    type: 'text',
    label: '移動MPOS（桌枱·落單·訂單）',
    value: [false, false, false],
  },
  {
    type: 'text',
    label: '支持收銀錢箱對接（部分型號）',
    value: [false, false, true],
  },
  {
    type: 'text',
    label: '支持不同打印機對接（部分型號）',
    value: [false, false, true],
  },
  {
    type: 'title',
    label: '*雲端平台（Q1完成1.0）',
    value: '',
  },
  {
    type: 'text',
    label: 'WEB-PC版本',
    value: [true, true, true],
  },
  {
    type: 'text',
    label: 'WEB-Mobile版本',
    value: [false, false, true],
  },
  {
    type: 'text',
    label: '即時數據',
    value: [false, false, true],
  },
  {
    type: 'text',
    label: '雲端-產品管理',
    value: [true, true, true],
  },
  {
    type: 'text',
    label: '雲端-菜式分組管理',
    value: [true, true, true],
  },
  {
    type: 'text',
    label: '雲端-餐廳設置管理',
    value: [false, false, true],
  },
  {
    type: 'text',
    label: '雲端-優惠活動管理',
    value: [false, false, false],
  },
  {
    type: 'text',
    label: '雲端-大數據分析',
    value: [false, false, false],
  },
  {
    type: 'title',
    label: '主機支持',
    value: '',
  },
  {
    type: 'text',
    label: '移動MPOS',
    value: [true, false, false],
  },
  {
    type: 'text',
    label: 'Android Pad',
    value: [true, true, true],
  },
  {
    type: 'text',
    label: 'Android一體機',
    value: [true, true, true],
  },
  {
    type: 'text',
    label: 'Android/iOS 手機',
    value: [true, false, false],
  },
  {
    type: 'title',
    label: '備註',
  },
  {
    type: 'text',
    label: '',
    value: [
      false,
      '針對連鎖品牌的外賣類型餐廳，按“分店數量 x 1,000港幣/月”來收費，自動升級提供“多賬號管理“、“多門店管理“以及“多門店的數據報表整合“等功能。',
      '如超過60個座位的單店餐廳仍然希望使用“中小型餐廳”方案，則超出60個的座位，按照30港幣/座位/月來加收系統費用。'
    ],
  }
]