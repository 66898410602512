export default [
  {
    type: 'caption',
    value: '服務員移動下單kit',
    extraValue: '服務員可隨身攜帶的下單設備。電子菜單可同步更新，下載即用，最大限度的提高服務員的下單效率，降低記錄過程中的出錯率'
  },
  {
    type: 'title',
    label: '設備支持',
  },
  {
    type: 'text',
    label: '支持Android手機設備',
  },
  {
    type: 'text',
    label: '支持iOS手機設備',
  },
  {
    type: 'text',
    label: '支持手持POS機/支持出票',
  },
  {
    type: 'title',
    label: '功能範圍',
  },
  {
    type: 'text',
    label: '桌台視圖',
  },
  {
    type: 'text',
    label: '電子菜單',
  },
  {
    type: 'text',
    label: '下單/加單',
  },
  {
    type: 'text',
    label: '訂單查看/操作',
  },
  {
    type: 'text',
    label: '支持每位服務員一個賬號',
  }
]