/** 定制服務 */
export default [
  {
    type: 'caption',
    label: '',
    value: '定制服務',
    extraValue: '大型/連鎖餐廳、FoodCourt、放題等',
  },
  {
    type: 'title',
    label: 'Food Court',
  },
  {
    type: 'text',
    label: '集合多家餐廳，檔口數量較多',
  },
  {
    type: 'text',
    label: '餐廳統一收銀，統一管理',
  },
  {
    type: 'text',
    label: '共享式餐位，顧客自由入座',
  },
  {
    type: 'text',
    label: '擁有堂吃/外帶/外賣等多種業務',
  },
  {
    type: 'text',
    label: '較為定制化的數據需求',
  },
  {
    type: 'text',
    label: '餐廳直接相互供貨/分成',
  },
  {
    type: 'text',
    label: '...',
  },
  {
    type: 'title',
    label: '連鎖餐廳',
  },
  {
    type: 'text',
    label: '包含多個門店的連鎖餐飲品牌',
  },
  {
    type: 'text',
    label: '擁有堂吃/外帶/外賣等多種業務',
  },
  {
    type: 'text',
    label: '統一的菜單、優惠管理',
  },
  {
    type: 'text',
    label: '跨門店的會員體系',
  },
  {
    type: 'text',
    label: '財務情況、銷售情況匯總管理',
  },
  {
    type: 'text',
    label: '...',
  },
  {
    type: 'title',
    label: '大型餐廳',
  },
  {
    type: 'text',
    label: '面積較大或餐位較多，一般超過60個餐位',
  },
  {
    type: 'text',
    label: '擁有堂吃/外帶/外賣等多種業務',
  },
  {
    type: 'text',
    label: '結構複雜，多樓層、多區域；員工、部門分工較細，各區域分工明確',
  },
  {
    type: 'text',
    label: '較為複雜的後廚結構或運營流程',
  },
  {
    type: 'text',
    label: '較為定制化的數據需求',
  },
  {
    type: 'text',
    label: '...',
  },
  {
    type: 'title',
    label: '放題',
  },
  {
    type: 'text',
    label: '按照人頭數、套餐類型、用餐時長收費',
  },
  {
    type: 'text',
    label: '特殊菜品/菜單加價供應',
  },
  {
    type: 'text',
    label: '顧客自助式點菜，加菜頻繁',
  },
  {
    type: 'text',
    label: '不同時間段提供不同的業務模式，如：午餐套餐定食，晚餐放題',
  },
  {
    type: 'text',
    label: '...',
  }
]